<template>
  <div>
    <Modal :options="{width: '60vw',close: true}" @close="cancelar_carga_imagen">
        <div class="title">Imagen</div>
        <div class="body">

            <template v-if="!imagen">
                <div class="row form-group">
                    <label for="" class="col-form-label col-sm-2">Imágen</label>
                    <div class="col-sm-10">
                        <div v-if="!selected_image" ref="file_box" class="load_image div-table">
                            <div class="div-cell-text" ref="droped_image" @click="abrir_select_imagen">
                                Arrastra o da click para cargar una imagen
                            </div>
                        </div>
                        <div v-else class="text-center file_preview">
                            <img class="file_preview_img" :src="image_file" alt="">
                            <div class="file_preview_info">
                                <span class="info">Ancho: {{ info.width }}px | Alto: {{ info.height }}px | Peso: {{ Math.round(info.size/1000) }}kb</span>
                                <span class="trash" title="Cambiar imagen" @click="selected_image=null"><i class="gg-trash-empty"></i></span>	
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="row form-group">
                    <label for="imagen_descripcion" class="col-form-label col-sm-2">Descripción</label>
                    <div class="col-sm-10">
                        <input v-model="descripcion" type="text" class="form-control" name="imagen_descripcion" id="imagen_descripcion">
                    </div>
                </div>
                <div class="row form-group">
                    <label for="imagen_visible" class="col-form-label col-sm-2">Visible</label>
                    <div class="col-sm-10">
                        <VSwitch v-model="visible" texton="Sí" textoff="No" id="imagen_visible" name="imagen_visible" />
                    </div>
                </div>
            </template>

			<template v-else>
					<div class="row form-group ">
                        <div v-show="false" ref="file_box">ref error</div>
						<label for="" class="col-form-label col-sm-2">Imágen</label>
						<div class="col-sm-10">
							<div class="text-center file_preview">
								<img class="file_preview_img" :src="imagen.file" alt="">
							</div>
						</div>
					</div>
					<div class="row form-group">
						<label for="imagen_descripcion" class="col-form-label col-sm-2">Descripción</label>
						<div class="col-sm-10">
							<input v-model="imagen.descripcion" type="text" class="form-control" name="imagen_descripcion" id="imagen_descripcion">
						</div>
					</div>
					<div class="row form-group">
						<label for="imagen_visible" class="col-form-label col-sm-2">Visible</label>
						<div class="col-sm-10">
							<VSwitch v-model="imagen.estatus" texton="Sí" textoff="No" id="imagen_visible" name="imagen_visible" />
						</div>
					</div>
            </template>            

           <input v-show="false" type="file" @change="image_in_input" ref="input_image">

        </div>
        <div class="footer">
            <div class="row">
                <div class="col-sm-2 offset-sm-8"><button class="btn btn-block btn-primary" @click="cargar_imagen">{{ !imagen ? 'Cargar' : 'Guardar Cambios' }}</button></div>
                <div class="col-sm-2"><button class="btn btn-block btn-danger" @click="cancelar_carga_imagen">Cancelar</button></div>
            </div>
        </div>
    </Modal>

  </div>
</template>

<script>
import Modal from "@/components/Modal";
import VSwitch from '@/components/Form/Switch'
import apiActivos from "@/apps/garantias/api/activos";


export default {
    props:{
        garantia_id:{
            type: Number,
            required: true,
        },
        garantia:{
            type: Object,
            required: true,
        },
        imagen:null,
    },
    data(){    
        return{
            modal_carga_imagen: false,
            selected_image: null,
            info: {
					width: '0px',
					height: '0px',
					size: 0,
            },
            descripcion: null,
            visible: 1,
        }
    },
    components:{ 
        Modal, VSwitch,
    },
    methods:{
        cancelar_carga_imagen(){
            this.modal_carga_imagen = false;
            this.selected_image = null;
            this.descripcion = null;
            this.visible = 1;

            this.info = {
                width: '0px'
                ,height: '0px'
                ,size: 0
            }                
            this.$emit('close');
        },
        image_in_input(event){
            this.selected_image = event.target.files[0];
        },
        async cargar_imagen(){
            try {
                if(!this.imagen){
                    if(this.descripcion === '' || this.descripcion === null)
                        return this.$helper.showMessage('Error','Tienes que especificar una descripción', 'error','alert');
                    if(this.selected_image === null)
                        return this.$helper.showMessage('Error','Tienes que especificar una imágen', 'error','alert');
                        
                    let formData = new FormData();
                        formData.append('imagen',this.selected_image);
                        formData.append('descripcion', this.descripcion);
                        formData.append('estatus', this.visible);
                        formData.append('fideicomiso', this.garantia.fideicomiso);
                    
                    await apiActivos.cargar_imagen(this.garantia_id, formData);
                    this.$helper.showMessage('Imagen Guardada','La imagen ha sido guardada en la garantía','success');
                }else{

                    if (!this.imagen.descripcion || this.imagen.descripcion === null)
                        return this.$helper.showMessage('Error','Tienes que especificar una descripción', 'error','alert');
                    await apiActivos.editar_imagen(this.imagen.id, {descripcion: this.imagen.descripcion, estatus: this.imagen.estatus});
                    this.$helper.showMessage('Información Actualizada','Los datos de la imagen han sido actualizados','success');
                }
                this.cancelar_carga_imagen();
                this.$emit('imagen_guardada');

            } catch (e) {
                this.$log.info('error',e)
                this.$helper.showAxiosError(e,'Error')
            }
        },
        abrir_select_imagen(){
            this.$refs.input_image.click();
        },
    },
    computed: {
        image_file(){
            if (this.selected_image)
                return URL.createObjectURL(this.selected_image);

            return 'https://via.placeholder.com/250x200.png';
        }
    },
    watch:{
        selected_image(val){
            if (this.selected_image) {
                let img = new Image();
                let that = this;
                img.onload = (e) => {
                    that.info.width = e.path[0].width;
                    that.info.height = e.path[0].height;
                    that.$log.info('img',e);
                }
                img.src = URL.createObjectURL(this.selected_image);
            }
        }        
    },
    mounted(){
        
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function(event) {
            this.$refs.file_box.addEventListener(event, function(e) {
                e.preventDefault();
                e.stopPropagation();
            }.bind(this), false);
        }.bind(this));

        this.$refs.file_box.addEventListener('drop', function(e) {
            if (e.dataTransfer.files.length > 0)
                this.selected_image = e.dataTransfer.files[0];
        }.bind(this));
    }
}
</script>

<style lang="scss" scoped>

    .load_image {
		width: 100%;
		min-height: 250px;
		// background-color: #E6E6E6;
		border: dashed 3px #E6E6E6;
		color: #808080;
	}
    
	.div-table {
		width: 100%;
		height: 100%;
		display: table;

		.div-cell-text{
			text-align: center;
			vertical-align: middle;
			display: table-cell;
		}

		.div-cell-img {
			width: 100%;
			height: 100%;
			vertical-align: middle;
			display: inline-block;
			text-align: center;

			img {
				vertical-align: middle;
			}
		}
	}

    .file_preview {
		height: 300px;
		padding: 5px;
		border: dashed 3px #E6E6E6;
		position: relative;

		.file_preview_img {
			height: 100%;
		}

		.file_preview_info {
			background-color: #E6E6E6;
			padding: 5px 10px;
			position: absolute;
			bottom: 5px;
			right: 5px;

			span {
				display:inline-block;

				i {
					margin-top: 10px;
					margin-left: 10px;
				}
			}
		}
	}

</style>